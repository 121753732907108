import { ComponentType, Suspense } from "react";
import { ContentfulEntry, EntryRef } from "frontend/contentful/schema/sys";
import { ErrorBoundary } from "design-system/components/system/error-boundary/error-boundary";
import { contentfulComponents } from "frontend/contentful/contentful-components";
import { useContentful } from "frontend/hooks/use-contentful";
import {
  ArchivePageEntry,
  PageTemplates,
} from "frontend/contentful/schema/pages";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import {
  ContentfulComponentRenderProps,
  ContentfulFactoryComponent,
} from "frontend/contentful/lib/create-contentful-component";

interface RenderContentfulEntryProps {
  entry?: EntryRef<ContentfulEntry> | null;
  options?: Record<string, unknown>;
}

const archiveTemplates: Record<
  PageTemplates,
  ContentfulFactoryComponent<ArchivePageEntry>
> = {
  "ExEd Program Finder": contentfulComponents.exedProgramFinderArchivePage,
  "Person Profiles": contentfulComponents.archivePage,
};

export function RenderContentfulEntry({
  entry,
  options,
}: RenderContentfulEntryProps) {
  const { getEntry } = useContentful();

  if (!entry) return null;

  entry = getEntry(entry);

  let Component: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ComponentType<ContentfulComponentRenderProps<any, any>> | undefined;

  if (
    isContentType<ArchivePageEntry>("archivePage", entry) &&
    entry.fields.template
  ) {
    Component = archiveTemplates[entry.fields.template];
  } else {
    const componentType = entry?.sys?.contentType?.sys?.id;
    Component = componentType
      ? contentfulComponents[componentType as keyof typeof contentfulComponents]
      : undefined;
  }

  if (Component) {
    return (
      <ErrorBoundary>
        <Suspense>
          <Component entry={entry} options={options} />
        </Suspense>
      </ErrorBoundary>
    );
  }

  return null;
}

import { EditAttributes } from "design-system/types/types";
import { ContentfulEntry, EntryRef } from "../schema/sys";

interface EditAttributesArgs {
  fieldId?: string;
  entryId?: string;
  entry?: EntryRef<ContentfulEntry>;
}

export type CreateEditAttributesFn = (
  args: EditAttributesArgs,
) => EditAttributes;

export function editAttributesFactory(
  isEditorMode?: boolean,
): CreateEditAttributesFn {
  return ({ fieldId, entry, entryId }) => {
    if (!isEditorMode) return null;
    return {
      "data-contentful-entry-id": entryId || entry?.sys?.id || "",
      "data-contentful-field-id": fieldId || "internalName",
    };
  };
}

import {
  PersonEntry,
  PersonWrapperEntry,
  SchoolLeadershipPersonDetailsEntry,
} from "frontend/contentful/schema/semantics";
import { ContentfulIncludes, EntryRef } from "../schema/sys";
import { resolveEntry } from "./resolve-includes";
import { isContentType } from "frontend/contentful/lib/is-content-type";

interface ResolvePersonNameArgs {
  entry?: EntryRef<
    PersonEntry | PersonWrapperEntry | SchoolLeadershipPersonDetailsEntry
  >;
  includes: ContentfulIncludes;
}

export function resolvePersonName({ entry, includes }: ResolvePersonNameArgs) {
  let name = "";
  if (!entry) return name;
  let person = resolveEntry(includes, entry);

  if (
    isContentType<SchoolLeadershipPersonDetailsEntry>(
      "schoolLeadershipPersonDetails",
      person,
    )
  ) {
    name = person.fields.personName || "";
    const personEntry = person.fields.person;
    person = resolveEntry(includes, personEntry);
  }

  if (isContentType<PersonWrapperEntry>("personWrapper", person)) {
    name =
      person.fields.displayName ||
      resolveEntry(includes, person.fields.person)?.fields.displayName ||
      "";
  } else if (!name && person?.fields.displayName) {
    name = person.fields.displayName;
  }

  if (!name && person) {
    const fields = isContentType<PersonWrapperEntry>("personWrapper", person)
      ? resolveEntry(includes, person.fields.person)?.fields
      : person.fields;
    name = [
      fields?.firstName,
      fields?.middleName,
      fields?.lastName,
      fields?.nameSuffix,
    ]
      .filter((name) => Boolean(name))
      .join(" ");
  }

  return name;
}

import type {
  AssetRef,
  ContentfulAsset,
  ContentfulEntry,
  ContentfulIncludes,
  ContentfulSysLink,
  EntryRef,
} from "../schema/sys";

export function resolveEntry<E extends ContentfulEntry>(
  includes: ContentfulIncludes,
  ref?: EntryRef<E>,
) {
  if (isEntryRef(ref)) {
    return includes.Entry.find((entry) => entry.sys?.id === ref.sys?.id) as E;
  } else {
    if (ref && typeof ref === "object") {
      if (
        "fields" in ref &&
        (ref as Record<string, unknown>).fields &&
        typeof (ref as Record<string, unknown>).fields === "object"
      ) {
        // is most likely an already resolved entry
        return ref as unknown as E;
      }
    }
  }
}

export function resolveAsset(
  includes: ContentfulIncludes,
  ref?: ContentfulSysLink,
): ContentfulAsset | undefined {
  if (isAssetRef(ref)) {
    return includes.Asset.find((asset) => asset.sys?.id === ref.sys?.id)!;
  } else {
    if (ref && typeof ref === "object") {
      if ("fields" in ref && ref.fields && typeof ref.fields === "object") {
        // is most likely an already resolved asset
        return ref as unknown as ContentfulAsset;
      }
    }
  }
}

export function isEntryRef(ref: unknown): ref is EntryRef<ContentfulEntry> {
  if (isLinkObj(ref)) {
    return ref.sys.linkType === "Entry";
  }

  return false;
}

export function isAssetRef(ref: unknown): ref is AssetRef {
  if (isLinkObj(ref)) {
    return ref.sys.linkType === "Asset";
  }

  return false;
}

function isLinkObj<T extends string>(
  ref: unknown,
): ref is ContentfulSysLink<string, T> {
  if (ref && typeof ref === "object") {
    if ("sys" in ref && ref.sys && typeof ref.sys === "object") {
      if ("id" in ref.sys && typeof ref.sys.id === "string") {
        if ("linkType" in ref.sys && typeof ref.sys.linkType === "string") {
          return true;
        }
      }
    }
  }

  return false;
}

import {
  Translations,
  translations as DEFAULT_TRANSLATIONS,
} from "design-system/i18n/translations";
import { PropsWithChildren, createContext, useContext } from "react";

export type TranslationFn = (key: string, locale?: string) => string;

export interface TranslationsContext {
  t: TranslationFn;
  strings: Translations;
}

export interface TranslationsProps {
  locale?: string;
  strings?: Translations;
}

const TranslationsContext = createContext<TranslationsContext>({
  t: (key) => key,
  strings: DEFAULT_TRANSLATIONS,
});

export function TranslationsProvider({
  strings = DEFAULT_TRANSLATIONS,
  locale: activeLocale = "en-US",
  children,
}: PropsWithChildren<TranslationsProps>) {
  const t: TranslationFn = (key, locale = activeLocale) => {
    if (!strings[locale]?.[key]) {
      console.warn(`Missing translation for key: ${key} in locale: ${locale}`);
    }

    return strings[locale]?.[key] || key;
  };

  return (
    <TranslationsContext.Provider value={{ strings, t }}>
      {children}
    </TranslationsContext.Provider>
  );
}

export function useTranslations() {
  return useContext(TranslationsContext);
}

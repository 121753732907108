import { type ComponentLibrary } from "design-system/components";
import { createContext, FC, PropsWithChildren, useContext } from "react";

const LazyComponentsContext = createContext<
  ComponentLibrary | Record<string, unknown>
>({});

interface LazyComponentsContextProps extends PropsWithChildren {
  components?: ComponentLibrary;
}

export function LazyComponentsProvider({
  components,
  children,
}: LazyComponentsContextProps) {
  return (
    <LazyComponentsContext.Provider value={components || {}}>
      {children}
    </LazyComponentsContext.Provider>
  );
}

export function useLazyComponents() {
  const lazyComponents = useContext(LazyComponentsContext);

  const getLazyComponentByName = (name: string) => {
    if (name && lazyComponents[name as keyof typeof lazyComponents]) {
      return lazyComponents[name as keyof typeof lazyComponents] as FC;
    }
  };

  return {
    lazyComponents,
    getLazyComponentByName,
  };
}

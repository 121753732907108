import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { locationSlice } from "./slice-location";
import { frameworkSlice } from "./slice-framework";

export type Store = ReturnType<typeof createStore>;

export function createStore() {
  return configureStore({
    reducer: {
      location: locationSlice.reducer,
      framework: frameworkSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["framework/initializeFramework"],
          ignoredPaths: ["payload.Head", "framework.Head"],
        },
      });
    },
  });
}

export const useStoreDispatch: () => Store["dispatch"] = useDispatch;
export const useStoreSelector: TypedUseSelectorHook<
  ReturnType<Store["getState"]>
> = useSelector;
export const useStoreComplete: () => Store = useStore;

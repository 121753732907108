import {
  PersonEntry,
  PersonWrapperEntry,
} from "frontend/contentful/schema/semantics";
import { ContentfulIncludes, EntryRef } from "../schema/sys";
import { resolveEntry } from "./resolve-includes";
import { resolveEntries } from "./resolve-entries";
import { isContentType } from "./is-content-type";

const isPastHbsFaculty = (
  includes: ContentfulIncludes,
  personEntry?: PersonEntry,
) => {
  const personTypes = resolveEntries({
    includes: includes,
    refs: personEntry?.fields.personType,
  });
  return personTypes.some((type) => type.fields.title === "Past HBS Faculty");
};

interface ResolveIsPersonPastHbsFacultyArgs {
  entry?: EntryRef<PersonEntry | PersonWrapperEntry>;
  includes: ContentfulIncludes;
}

export function resolveIsPersonPastHbsFaculty({
  entry,
  includes,
}: ResolveIsPersonPastHbsFacultyArgs) {
  const resolvedEntry = resolveEntry(includes, entry);

  if (isContentType<PersonWrapperEntry>("personWrapper", resolvedEntry)) {
    const personEntry = resolveEntry(includes, resolvedEntry.fields.person);
    return isPastHbsFaculty(includes, personEntry);
  }

  return isPastHbsFaculty(includes, resolvedEntry);
}

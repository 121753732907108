import { ContentfulEntry, ExtractEntry } from "frontend/contentful/schema/sys";

export function isContentType<T extends ContentfulEntry>(
  id: ExtractEntry<T>["id"],
  value?: ContentfulEntry,
): value is T {
  if (!value || typeof value !== "object") return false;

  if ("sys" in value && value.sys && typeof value.sys === "object") {
    if (
      "contentType" in value.sys &&
      value.sys.contentType &&
      typeof value.sys.contentType === "object"
    ) {
      if (
        "sys" in value.sys.contentType &&
        value.sys.contentType.sys &&
        typeof value.sys.contentType.sys === "object"
      ) {
        if ("id" in value.sys.contentType.sys && value.sys.contentType.sys.id) {
          return value.sys.contentType.sys.id === id;
        }
      }
    }
  }

  return false;
}

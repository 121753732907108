import { AssetRef, ContentfulAsset, ContentfulIncludes } from "../schema/sys";
import { resolveAsset } from "./resolve-includes";

interface ResolveAssetsArgs {
  refs?: Array<AssetRef>;
  includes: ContentfulIncludes;
}

export function resolveAssets({ refs, includes }: ResolveAssetsArgs) {
  if (!Array.isArray(refs)) return [];

  const assets: Array<ContentfulAsset> = [];

  refs.forEach((ref) => {
    const asset = resolveAsset(includes, ref);
    if (asset) assets.push(asset);
  });

  return assets;
}

import { ContentfulEntry, ContentfulIncludes, EntryRef } from "../schema/sys";
import { resolveEntry } from "./resolve-includes";

interface ResolveEntriesArgs<E extends ContentfulEntry> {
  refs?: Array<EntryRef<E>>;
  includes: ContentfulIncludes;
}

export function resolveEntries<E extends ContentfulEntry>({
  refs,
  includes,
}: ResolveEntriesArgs<E>) {
  if (!Array.isArray(refs)) return [];

  const entries: Array<E> = [];

  refs.forEach((ref) => {
    const entry = resolveEntry(includes, ref);
    if (entry) entries.push(entry);
  });

  return entries;
}

import { AssetRef, ContentfulIncludes } from "../schema/sys";
import { resolveAsset } from "./resolve-includes";

const CONTENTFUL_ASSET_DOMAIN = "assets.ctfassets.net";
const CONTENTFUL_IMAGE_DOMAIN = "images.ctfassets.net";

interface ResolveAssetUrlArgs {
  asset?: AssetRef;
  includes: ContentfulIncludes;
}

export function resolveAssetUrl({ asset, includes }: ResolveAssetUrlArgs) {
  const resolved = resolveAsset(includes, asset);

  const url = resolved?.fields?.file?.url;

  if (url) {
    let assetUrl = url
      .replace(CONTENTFUL_ASSET_DOMAIN, "webassets.hbs.edu")
      .replace(CONTENTFUL_IMAGE_DOMAIN, "webassets.hbs.edu");

    // contentful asset URLs begin with "//"
    if (assetUrl.startsWith("//")) assetUrl = "https:" + assetUrl;
    return assetUrl;
  }

  return url;
}

export function cleanAssetUrl(url: string): string {
  let assetUrl = url
    .replace(CONTENTFUL_ASSET_DOMAIN, "webassets.hbs.edu")
    .replace(CONTENTFUL_IMAGE_DOMAIN, "webassets.hbs.edu");

  // contentful asset URLs begin with "//"
  if (assetUrl.startsWith("//")) assetUrl = "https:" + assetUrl;

  return assetUrl;
}
